import React from 'react';
import cn from 'classnames';

import { CommonComponent, RoutedComponent } from '../../../types';

import { Text, UnstyledButton } from '../../../common';

// classes: styles/components/_titleBar.scss

export interface PillProps extends CommonComponent, RoutedComponent {
  children: React.ReactNode | string;
  onClick?: (ev: React.MouseEvent) => void,
  color?: 'primary' | 'secondary' | 'danger',
}

export const Pill = ({
  children,
  className,
  component,
  color,
  ...routeProps
}: PillProps) => {
  const isInteractive = routeProps.href || routeProps.onClick;
  const Component = component ?? (isInteractive ? UnstyledButton : Text);

  return (
    <Component
      className={cn(className, 'jobox__pill', `jobox__pill--${color || 'secondary'}`, {
        'jobox__pill--interactive': isInteractive,
      })}
      {...routeProps}
    >
      { children }
    </Component>
  );
};
