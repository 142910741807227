import React from 'react';
import cn from 'classnames';

import { Box } from '../../../common/components/box';

import { CommonComponent } from '../../../types';

import defaultLoader from './defaultLoader.svg';

// ContentLoaderBody ----------------------------
export interface ContentLoaderBodyProps extends CommonComponent {
  children: React.ReactNode;
}

export const ContentLoaderBody = ({
  className,
  children,
}: ContentLoaderBodyProps) => (
  <Box className={cn('jobox__content-loader__body', className)}>
    {
      children || (
        <img src={defaultLoader} alt="Loading" className="jobox__content-loader__spinner" />
      )
    }
  </Box>
);

// ContentLoader --------------------------------
export interface ContentLoaderProps {
  children: React.ReactNode;
  loading: boolean;
}

export const ContentLoader = ({ children, loading }: ContentLoaderProps) => {
  const body = React.Children.toArray(children)
    .filter((child) => React.isValidElement(child))[0] as (React.ReactElement<any> | undefined);

  return body ? React.cloneElement(body, {
    ...body.props,
    className: cn(body.props?.className, 'jobox__content-loader', {
      'jobox__content-loader--visible': loading,
    }),
  }) : null;
};
