import React, { useContext } from 'react';
import cn from 'classnames';
import {
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { Flipped } from 'react-flip-toolkit';

import { Box } from '../../../common/components/box';
import { Text } from '../../../common/components/text';
import { Icon } from '../../../common/components/icon';
import { ListContext } from './listContext';

import { CommonComponent } from '../../../types';

// classes: styles/components/_list.scss

export interface ListGroupProps extends CommonComponent {
  children?: React.ReactNode;
  title?: String | React.ReactNode;
  id: string | number;
  collapsible?: boolean;
  onClick?: (id: string | number) => void;
}

// eslint-disable-next-line max-len
export const ListGroup = ({
  children,
  className,
  title,
  id,
  onClick = () => {},
  collapsible = true,
}: ListGroupProps) => {
  const { openGroupId, toggleOpenGroupId } = useContext(ListContext);

  const isOpen = collapsible ? openGroupId === id : true;

  const alteredChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        nested: true,
      });
    }
    return child;
  });

  const listElementInner = (
    <>
      {
        typeof title === 'string' ? (
          <Text className="jobox__list-group__title">{ title }</Text>
        ) : (
          <Box className="jobox__list-group__title">{ title }</Box>
        )
      }
      {
        collapsible && (
          <Icon icon={isOpen ? faChevronUp : faChevronDown} className="jobox__list-group__indicator" />
        )
      }
    </>
  );

  const listElement = (
    <li
      className={cn('jobox__list-group', 'jobox__list-item', className, {
        'jobox__list-group--open': isOpen,
      })}
    >
      {
        (onClick || collapsible) ? (
          <button
            type="button"
            className="jobox__list-group__header"
            onClick={() => {
              if (collapsible) {
                toggleOpenGroupId(id);
              }
              onClick(id);
            }}
          >
            { listElementInner }
          </button>
        ) : (
          <Box className="jobox__list-group__header">
            { listElementInner }
          </Box>
        )
      }
      <ul className={cn('jobox__list-group__inner', className)}>
        { alteredChildren }
      </ul>
    </li>
  );

  return collapsible ? (
    <Flipped
      flipId={`list-group-${id}`}
      stagger="list-item"
      translate
    >
      { listElement }
    </Flipped>
  ) : listElement;
};
