import React, { useContext, useCallback } from 'react';
import cn from 'classnames';

import { Box } from '../box';
import { InputFieldContext } from './inputFieldContext';
import { CommonComponent } from '../../../types';

export interface InputProps extends CommonComponent{
  type?: 'text' | 'multiline' | 'number';
  name?: string;
  id?: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string | number | undefined;
  min?: number;
  max?: number;
  step?: number;
  label?: string;
  placeholder?: string;
  align?: 'left' | 'right';
  addOn?: React.ReactElement;
}

export const Input = (props: InputProps) => {
  const fieldContext = useContext(InputFieldContext);

  // eslint-disable-next-line react/destructuring-assignment
  if (!fieldContext && (!props.type || !props.name)) {
    throw new Error('Input: InputField provider not found, you need to provide the type, name and other props by yourself.');
  }

  const inputProps = {
    ...fieldContext.field,
    ...props,
  };
  const {
    type,
    className,
    id,
    label,
    onBlur,
    onFocus,
    align,
    addOn,
    ...otherInputProps
  } = inputProps;
  const { setIsFocused } = fieldContext;
  const inputClassName = cn(
    'jobox__input',
    `jobox__input--align-${align || 'left'}`,
    'jobox__input-wrap__input',
    className,
  );

  const focusCallbacks = {
    onBlur: useCallback(
      (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFocused(false);
        if (onBlur) {
          onBlur(ev);
        }
      },
      [],
    ),
    onFocus: useCallback(
      (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFocused(true);
        if (onFocus) {
          onFocus(ev);
        }
      },
      [],
    ),
  };

  return (
    <Box className="jobox__input-wrap">
      {
        label && (
          <label htmlFor={id} className="jobox__input-wrap__label">
            { label }
          </label>
        )
      }
      <Box className="jobox__input-wrap__inner">
        { addOn }
        {
          type === 'multiline' ? (
            <textarea id={id} className={cn(inputClassName, 'jobox__input--multiline')} {...{ ...otherInputProps, ...focusCallbacks }} />
          ) : (
            <input id={id} type={type || 'text'} className={inputClassName} {...{ ...otherInputProps, ...focusCallbacks }} />
          )
        }
      </Box>
    </Box>
  );
};
