import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { Flipper } from 'react-flip-toolkit';

import { ListContext } from './listContext';

import { CommonComponent } from '../../../types';

// classes: styles/components/_list.scss

export interface ListProps extends CommonComponent {
  children: React.ReactNode;
  onSelectId: (id: string | number | null) => void;
  selectedId: string | number | null;
}

export const List = ({
  children,
  className,
  onSelectId,
  selectedId,
}: ListProps) => {
  const [openGroupId, setOpenGroupId] = useState<string | number | null>(null);

  const contextValue = useMemo(() => ({
    openGroupId,
    toggleOpenGroupId: (groupId: string | number) => {
      setOpenGroupId(
        openGroupId === groupId ? null : groupId,
      );
    },
    selectable: (
      typeof onSelectId !== 'undefined'
      || typeof selectedId !== 'undefined'
    ),
    onSelectId,
    selectedId,
  }), [openGroupId, setOpenGroupId, onSelectId, selectedId]);

  return (
    <ListContext.Provider
      value={contextValue}
    >
      <Flipper
        flipKey={contextValue.openGroupId}
        staggerConfig={{
          'list-item': {
            reverse: !!openGroupId,
          },
        }}
        spring={{
          stiffness: 600,
          damping: 50,
        }}
      >
        <ul className={cn('jobox__list', className)}>
          { children }
        </ul>
      </Flipper>
    </ListContext.Provider>
  );
};
