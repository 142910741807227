import React from 'react';

import FeatureFlagContext from './featureFlagsContext';

const withFeatureFlagProvider = (Component: React.ElementType) => (
  // eslint-disable-next-line react/prefer-stateless-function
  class FeatureFlagProviderWrap extends React.Component {
    render() {
      return (
        <FeatureFlagContext.Consumer>
          {
            ({ flags }) => (
              <Component {...this.props} flags={flags} />
            )
          }
        </FeatureFlagContext.Consumer>
      );
    }
  }
);

export default withFeatureFlagProvider;
