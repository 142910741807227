import { useEffect, useState } from 'react';

import { parseTransitionString } from '../utils';
import { usePrevious } from './usePrevious';

export function useTransitionShouldRender({ open }: { open: boolean }) {
  const [transitionDuration, setTransitionDuration] = useState(0);
  const [shouldRender, setShouldRender] = useState(open);
  const [visible, setVisible] = useState(false);
  const prevOpen = usePrevious(open);

  const updateTransitionDuration = (ref?: HTMLDivElement) => {
    if (ref && typeof window.getComputedStyle === 'function') {
      const computedStyle = window.getComputedStyle(ref);
      setTransitionDuration(
        parseTransitionString(computedStyle.transitionDuration),
      );
    }
  };

  useEffect(() => {
    if (open !== prevOpen) {
      if (open) {
        setShouldRender(true);
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            setVisible(true);
          });
        });
      } else {
        setVisible(false);
        setTimeout(() => {
          setShouldRender(false);
        }, transitionDuration);
      }
    }
  }, [open, prevOpen]);

  return {
    visible,
    shouldRender,
    transitionedElementRef: updateTransitionDuration,
  };
}
