import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface CardHeaderProps extends CommonComponent {
  children: React.ReactNode;
  color: 'default' | 'primary' | 'danger',
}

export const CardHeader = ({ children, className, color }: CardHeaderProps) => (
  <div
    className={cn(className, 'jobox__card__header', `jobox__card__header--${color || 'default'}`)}
  >
    { children }
  </div>
);
