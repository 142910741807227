import React from 'react';
import cn from 'classnames';

import { Box } from '../../../common/components/box';
import { CommonComponent } from '../../../types';

// classes: styles/components/_viewLayout.scss

// ViewHeader ===================================
export interface ViewHeaderContentProps extends CommonComponent {
  children?: React.ReactNode;
  nav?: boolean;
}

export const ViewHeaderContent = ({
  children,
  className,
  nav,
}: ViewHeaderContentProps) => (
  <Box
    className={cn('jobox__view-header-content', className, {
      'jobox__view-header-content--nav': nav,
    })}
  >
    { children }
  </Box>
);
