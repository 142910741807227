export const number = (message = 'Value needs to be a Number') => (value: string | number | undefined) => {
  if (
    typeof value === 'undefined'
    || value === ''
    || typeof value === 'number'
  ) {
    return undefined;
  }

  const parsed = parseFloat(value);

  return Number.isNaN(parsed) ? message : undefined;
};
