import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface ListLabelProps extends CommonComponent {
  children?: React.ReactNode | string;
}

export const ListLabel = ({
  children,
  className,
}: ListLabelProps) => (
  <h5 className={cn(className, 'jobox__list-label')}>
    { children }
  </h5>
);
