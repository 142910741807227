import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface CardFooterProps extends CommonComponent {
  children: React.ReactNode;
}

export const CardFooter = ({ children, className }: CardFooterProps) => (
  <div className={cn(className, 'jobox__card__footer')}>
    { children }
  </div>
);
