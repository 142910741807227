import React from 'react';
import { Route, RouteProps } from 'react-router';

import useFeatureFlag from './useFeatureFlag';
import useFeatureFlagsInitialized from './useFeatureFlagsInitialized';

interface FeatureFlagsRoute extends RouteProps {
  flag: string,
  fallback: React.ReactNode;
}

const FeatureFlagRoute = ({
  flag,
  fallback,
  ...routeProps
}: FeatureFlagsRoute) => {
  const featEnabled = useFeatureFlag(flag);
  const featureFlagsInitialized = useFeatureFlagsInitialized();

  if (!featureFlagsInitialized) {
    return null;
  }
  if (featEnabled) {
    return (
      <Route {...routeProps} />
    );
  }
  return fallback || null;
};

export default FeatureFlagRoute;
