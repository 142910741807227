import React, { useEffect, useRef, forwardRef } from 'react';
import {
  Formik,
  FieldArray,
  FormikProps,
  FormikErrors,
} from 'formik';
import _ from 'lodash';
import { CommonComponent } from '../../../types';

// Helper Component =============================
// Via useEffect provides a onChange callback which eliminates hacky
// code down the components tree
interface FormInnerProps<TValues = unknown> extends FormikProps<TValues>, CommonComponent {
  onChange: (values: TValues, prevValues: TValues, formikProps: FormikProps<TValues>) => void,
  children: React.ReactNode;
}

function FormInner<TValues = unknown>({
  children,
  className,
  onChange,
  ...formikProps
}: FormInnerProps<TValues>) {
  const prevValues = useRef<TValues>();
  const prevErrors = useRef<FormikErrors<TValues>>();

  useEffect(() => {
    const { values, errors } = formikProps;

    if (onChange && (!(
      _.isEqual(prevValues.current, values)
      && _.isEqual(prevErrors.current, errors)
    ))) {
      onChange(values, prevValues.current, formikProps);

      prevErrors.current = errors;
      prevValues.current = values;
    }
  }, [onChange, formikProps]);

  return (
    <form
      onSubmit={formikProps.handleSubmit}
      className={className}
      noValidate
    >
      {children}
    </form>
  );
}

// Main Form Component ==========================
export interface FormRef<TValues> {
  submit: () => void;
  reset: () => void;
  setValues: (values: TValues, validate?: boolean) => void,
}

interface FormProps<TValues> extends FormikProps<TValues>, CommonComponent {
  onSubmit: (values: TValues) => void,
  onChange: (values: TValues, prevValues: TValues, formikProps: FormikProps<TValues>) => void,
  children: React.ReactNode;
}

function Form<TValues>(
  {
    initialValues,
    onSubmit,
    children,
    className,
    onChange,
    ...formikProps
  }: FormProps<TValues>,
  ref: React.MutableRefObject<FormRef<TValues>>,
) {
  const formikRef = useRef<FormikProps<TValues>>(null);

  useEffect(() => {
    if (ref && formikRef.current) {
      const { current } = ref;
      const { resetForm, submitForm, setValues } = formikRef.current;

      current.reset = resetForm;
      current.submit = submitForm;
      current.setValues = setValues;
    }
  }, [ref, formikRef]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...formikProps}
    >
      {
        (props) => (
          <FormInner
            className={className}
            onChange={onChange}
            {...props}
          >
            {children instanceof Function ? children(props) : children}
          </FormInner>
        )
      }
    </Formik>
  );
}

const FormWithRef = forwardRef(Form);

export {
  FormWithRef as Form,
  FieldArray,
};
