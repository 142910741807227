import React from 'react';
import cn from 'classnames';

import { Box } from '../box';

import { CommonComponent } from '../../../types';

export interface InputAddonProps extends CommonComponent {
  children: React.ReactNode;
}

export const InputAddon = ({
  children,
  className,
}: InputAddonProps) => (
  <Box className={cn('jobox__input-addon', className)}>
    { children }
  </Box>
);
