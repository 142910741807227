import { FieldProps, FieldConfig } from 'formik';
import React, { createContext } from 'react';

export const InputFieldContext = createContext<FieldProps & {
  type: 'text' | 'multiline' | 'number',
  name: string,
  isFocused: boolean,
  setIsFocused:(focused: boolean) => void,
  field: FieldConfig & {
    onBlur: (ev?: React.FocusEvent) => void,
    onChange: (value: React.FormEvent | (string | number | null)) => void,
  }
}>({} as any);
