import React from 'react';
import cn from 'classnames';

import { Box } from '../../../common/components/box';

import { CommonComponent } from '../../../types';
import { useTransitionShouldRender } from '../../../hooks/useTransitionShouldRender';

// ModalDialogHeader ----------------------------
export interface ModalDialogHeaderProps extends CommonComponent {
  children?: string | React.ReactNode;
}

export const ModalDialogHeader = ({
  children,
  className,
}: ModalDialogHeaderProps) => (
  <h2 className={cn('jobox__modal-dialog__header', className)}>
    { children }
  </h2>
);

// ModalDialogContent ---------------------------
export interface ModalDialogContentProps extends CommonComponent {
  children?: string | React.ReactNode;
}

export const ModalDialogContent = ({
  children,
  className,
}: ModalDialogContentProps) => (
  <Box className={cn('jobox__modal-dialog__content', className)}>
    { children }
  </Box>
);

// ModalDialogActions ---------------------------
export interface ModalDialogActionsProps extends CommonComponent {
  children?: string | React.ReactNode;
}

export const ModalDialogActions = ({
  children,
  className,
}: ModalDialogActionsProps) => (
  <Box className={cn('jobox__modal-dialog__actions', className)}>
    { children }
  </Box>
);

// ModalDialog ----------------------------------
export interface ModalDialogProps extends CommonComponent {
  classNameBackdrop;
  children?: React.ReactNode;
  open: boolean;
  onToggle: () => void,
}

export const ModalDialog = ({
  children,
  className,
  classNameBackdrop,
  open,
}: ModalDialogProps) => {
  const {
    visible,
    shouldRender,
    transitionedElementRef,
  } = useTransitionShouldRender({ open });

  return shouldRender ? (
    <Box
      ref={transitionedElementRef}
      className={cn('jobox__backdrop', classNameBackdrop, {
        'jobox__backdrop--active': visible,
      })}
    >
      <Box
        className={cn('jobox__modal-dialog', className, {
          'jobox__modal-dialog--active': visible,
        })}
      >
        { children }
      </Box>
    </Box>
  ) : null;
};
