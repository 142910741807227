import React from 'react';
import {
  Field as FormikField,
  FieldProps as FormikFieldProps,
} from 'formik';

export interface FieldProps extends FormikFieldProps {
  children: React.ReactElement | ((fieldProps: FormikFieldProps) => React.ReactNode);
}

export const Field = ({ children, ...fieldProps }: FieldProps) => (
  <FormikField {...fieldProps}>
    {
      (props) => {
        if (typeof children === 'function') {
          return children(props);
        }
        if (React.isValidElement(children)) {
          return React.cloneElement(children, {
            ...(children as React.ReactElement).props,
            ...props,
          });
        }

        throw new Error('Invalid child, expected InputField, or render function');
      }
    }
  </FormikField>
);
