import React, { forwardRef, MutableRefObject } from 'react';

import { CommonComponent } from '../../../types';

export interface BoxProps extends CommonComponent {
  style?: React.CSSProperties,
  children?: React.ReactNode,
}

export const Box = forwardRef((props: BoxProps, ref: MutableRefObject<HTMLDivElement>) => (
  <div {...props} ref={ref} />
));
