const secondsRegex = /(\d+\.?\d+)s/i;
const milisecondsRegex = /(\d+\.?\d+)ms/i;

export function parseTransitionString(transitionStr?: string): number {
  if (transitionStr) {
    const secondsMatches = transitionStr.match(secondsRegex);
    if (secondsMatches) {
      return parseFloat(secondsMatches[1]) * 1000;
    }
    const msMatches = transitionStr.match(milisecondsRegex);
    if (msMatches) {
      return parseFloat(msMatches[1]);
    }
  }
  return 0;
}
