import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

// classes: styles/components/_titleBar.scss

// TitleBar =====================================
export interface TitleBarProps extends CommonComponent {
  children: React.ReactNode;
}

export const TitleBar = ({ children, className }: TitleBarProps) => (
  <header className={cn('jobox__title-bar', className)}>
    { children }
  </header>
);

// TitleBarTitle ================================
export interface TitleBarTitleProps extends CommonComponent {
  children: React.ReactNode;
}

export const TitleBarTitle = ({ children, className }: TitleBarProps) => (
  <h1 className={cn('jobox__title-bar__title', className)}>
    { children }
  </h1>
);

// TitleBarAction ===============================
export interface TitleBarActionProps extends CommonComponent {
  children?: React.ReactNode;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

export const TitleBarAction = ({ className, ...otherProps }: TitleBarProps) => (
  <button
    className={cn('jobox__title-bar__action', className)}
    type="button"
    {...otherProps}
  />
);
