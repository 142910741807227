import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface CardBodyProps extends CommonComponent {
  children: React.ReactNode;
}

export const CardBody = ({ children, className }: CardBodyProps) => (
  <div className={cn(className, 'jobox__card__body')}>
    { children }
  </div>
);
