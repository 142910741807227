import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

// classes: styles/components/_button.scss

export interface UnstyledButtonProps extends CommonComponent {
  children: React.ReactNode | string,
  type?: 'button' | 'submit' | 'reset',
  color?: 'primary' | 'secondary' | 'danger',
  href?: string,
  component?: React.ElementType,
  disabled?: boolean,
  onClick?: (ev: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
}

export const UnstyledButton = (props: UnstyledButtonProps) => {
  const {
    children,
    type,
    className,
    disabled,
    onClick,
    component,
    href,
  } = props;
  const commonProps = {
    onClick,
    className: cn('jobox__button--unstyled', className, {
      'jobox__button--disabled': disabled,
    }),
  };
  if (href || component === 'a') {
    return (
      <a
        href={href}
        {...commonProps}
      >
        { children }
      </a>
    );
  }

  if (!component || component === 'button') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type ?? 'button'}
        disabled={disabled}
        {...commonProps}
      >
        { children }
      </button>
    );
  }

  const Component = component;

  return (
    <Component
      className={commonProps.className}
      {...props}
    />
  );
};
