import React, { useMemo, useState } from 'react';
import { FieldProps } from 'formik';
import cn from 'classnames';

import { Box } from '../box';
import { InputFieldContext } from './inputFieldContext';
import { InputFeedback } from './inputFeedback';

import { CommonComponent } from '../../../types';

export interface InputFieldProps extends FieldProps, CommonComponent {
  type: 'text' | 'multiline' | 'number';
  name: string;
  children: React.ReactNode;
}

export const InputField = ({
  type,
  name,
  children,
  ...fieldProps
}: InputFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputFieldContext.Provider
      value={useMemo(() => ({
        type,
        name,
        isFocused,
        setIsFocused,
        ...fieldProps,
      }), [fieldProps, type, name])}
    >
      <Box
        className={cn('jobox__input-field', {
          'jobox__input-field--focused': isFocused,
          'jobox__input-field--empty': fieldProps.field.value === '',
        })}
      >
        <Box className="jobox__input-field__input">
          { children }
        </Box>
        {
          (fieldProps.meta.touched && fieldProps.meta.error) && (
            <InputFeedback>
              { fieldProps.meta.error }
            </InputFeedback>
          )
        }
      </Box>
    </InputFieldContext.Provider>
  );
};
