import { createContext } from 'react';

export const ListContext = createContext<{
  openGroupId: string | number | null,
  toggleOpenGroupId:(groupId: string | number) => void,
  onSelectId: (id: string | number | null) => void;
  selectable: boolean,
  selectedId: string | number | null;
}>({
      openGroupId: null,
      toggleOpenGroupId: () => {
        throw new Error('toggleOpenGroupId is not provided');
      },
      onSelectId: () => {
        throw new Error('toggleOpenGroupId is not provided');
      },
      selectable: false,
      selectedId: null,
    });
