import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface InputFeedbackProps extends CommonComponent {
  children: React.ReactNode;
}

export const InputFeedback = ({ children, className }: InputFeedbackProps) => (
  <p className={cn(className, 'jobox__input-feedback')}>
    { children }
  </p>
);
