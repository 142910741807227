import React, { useState } from 'react';
import {
  Field,
  FieldProps as FormikFieldProps,
} from 'formik';

import { CustomInputProps } from '../../../types';

export interface CustomFieldProps extends FormikFieldProps {
  children: React.ReactElement;
}

export function CustomField<TValue = unknown>({ children, ...fieldProps }: CustomFieldProps) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Field {...fieldProps}>
      {
        (formik: FormikFieldProps) => (
          React.cloneElement(
            React.Children.only(children) as React.ReactElement<CustomInputProps<TValue>>,
            {
              ...formik,
              field: {
                ...formik.field,
                onChange: (value: TValue) => {
                  formik.form.setFieldValue(formik.field.name, value);
                },
                onFocus: () => {
                  setIsFocused(true);
                },
                onBlur: () => {
                  if (isFocused) {
                    formik.form.setFieldTouched(formik.field.name, true);
                  }
                },
              },
            },
          )
        )
      }
    </Field>
  );
}
