import React, { useContext } from 'react';
import cn from 'classnames';
import { Flipped } from 'react-flip-toolkit';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import { Box } from '../../../common/components/box';
import { Icon } from '../../../common/components/icon';
import { ListContext } from './listContext';

import { CommonComponent } from '../../../types';

// classes: styles/components/_list.scss

export interface ListItemProps extends CommonComponent {
  children: React.ReactNode;
  id: string | number;
  nested?: boolean;
  selected?: boolean;
  selectable?: boolean;
  onSelected?: (id: string | number) => void;
  component?: React.ElementType,
  renderIndicator?: (props: {
    selected: boolean,
    classes: {
      active: string,
      inactive: string,
    }
  }) => React.ReactNode;
}

export const ListItem = ({
  children,
  className,
  id,
  nested,
  component,
  selectable: selectableProp = true,
  renderIndicator,
}: ListItemProps) => {
  const {
    selectable: selectableCtx,
    onSelectId = () => {},
    selectedId,
  } = useContext(ListContext);

  const Component = component;
  const selectable = typeof selectableProp !== 'undefined' ? selectableProp : selectableCtx;
  const isSelected = selectable && selectedId === id;

  const innerElement = (
    <>
      <Box className="jobox__list-item__trigger__content">
        { children }
      </Box>

      {
        selectable && (
          <Box
            className={cn('jobox__list-item__trigger__indicator', 'jobox__list-item__check', {
              'jobox__list-item__check--active': isSelected,
            })}
          >
            {
              renderIndicator && renderIndicator({
                selected: isSelected,
                classes: {
                  inactive: 'jobox__list-item__check__inactive',
                  active: 'jobox__list-item__check__active',
                },
              })
            }
            {
              typeof renderIndicator === 'undefined' && (
                <>
                  <Icon className="jobox__list-item__check__inactive" icon={faCircle} />
                  <Icon className="jobox__list-item__check__active" icon={faCheckCircle} />
                </>
              )
            }
          </Box>
        )
      }
    </>
  );

  return (
    <Flipped
      flipId={`list-item-${id}`}
      stagger={nested ? 'list-item-nested' : 'list-item'}
      translate={!nested}
      opacity
    >
      <li className={cn('jobox__list-item', className)}>
        {
          typeof Component !== 'undefined' && (
            <Component className="jobox__list-item__trigger">
              { innerElement }
            </Component>
          )
        }
        {
          (selectable && !Component) && (
            <button
              type="button"
              className="jobox__list-item__trigger"
              onClick={() => onSelectId(id)}
            >
              { innerElement }
            </button>
          )
        }
        {
          (!selectable && !Component) && innerElement
        }
      </li>
    </Flipped>
  );
};
