import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface ToggleButtonProps extends CommonComponent {
  id?: string | number,
  children?: React.ReactNode,
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void,
  onActivated?: (activatedId: string | number) => void,
  isActive?: boolean,
}

export const ToggleButton = ({
  children,
  onClick,
  onActivated,
  isActive,
  id,
  className,
}: ToggleButtonProps) => {
  const clickHandler = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(ev);
    }
    if (onActivated && id) {
      onActivated(id);
    }
  };

  return (
    <button
      type="button"
      onClick={clickHandler}
      className={cn('jobox__toggle-button', className, {
        'jobox__toggle-button--active': isActive,
      })}
    >
      { children }
    </button>
  );
};
