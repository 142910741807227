import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface LinkProps extends CommonComponent {
  href?: string;
  onClick?: (ev: React.MouseEvent) => void;
  children: React.ReactNode | string;
}

export const Link = ({ className, children, ...otherProps }: LinkProps) => (
  <a className={cn(className, 'jobox__link')} {...otherProps}>
    { children }
  </a>
);
