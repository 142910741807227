import React from 'react';
import cn from 'classnames';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { Button, ButtonProps } from '../button';
import { Icon } from '../icon';

export interface ButtonLoadingProps extends ButtonProps {
  loading?: boolean,
  loaderIcon?: React.ReactElement,
  loadingText?: string,
}

export const ButtonLoading = ({
  loading,
  disabled,
  loaderIcon,
  children,
  className,
  loadingText,
  ...buttonProps
}: ButtonLoadingProps) => {
  const loader = loaderIcon || <Icon icon={faCircleNotch} />;

  return (
    <Button
      className={cn(className, 'jobox__button-loading', {
        'jobox__button-loading--loading': loading,
      })}
      disabled={disabled || loading}
      {...buttonProps}
    >
      <span className="jobox__button-loading__content">
        {
          !loading && children
        }
      </span>
      {
        React.cloneElement(loader, {
          className: 'jobox__button-loading__spinner',
        })
      }
    </Button>
  );
};
