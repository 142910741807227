import React from 'react';
import cn from 'classnames';

import {
  UnstyledButton,
  UnstyledButtonProps,
} from '../unstyledButton';

// classes: styles/components/_button.scss

export interface ButtonProps extends UnstyledButtonProps {
  color?: 'primary' | 'secondary' | 'danger',
}

export const Button = ({ color, className, ...otherProps }: ButtonProps) => (
  <UnstyledButton
    className={cn('jobox__button', `jobox__button--${color || 'primary'}`, className)}
    {...otherProps}
  />
);
