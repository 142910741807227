import * as LDClient from 'launchdarkly-js-client-sdk';

let ldClientInstance: LDClient.LDClient;

function getLdClient(clientId: string, user: LDClient.LDUser) {
  return new Promise<LDClient.LDClient>((resolve, reject) => {
    if (!ldClientInstance) {
      ldClientInstance = LDClient.initialize(
        clientId,
        user,
      );

      ldClientInstance.on('ready', () => { resolve(ldClientInstance); });
    } else {
      ldClientInstance.identify(user, null, () => { resolve(ldClientInstance); });
    }

    ldClientInstance.on('failed', reject);
    ldClientInstance.on('error', reject);
  });
}

export default getLdClient;
