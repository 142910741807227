import React, { useEffect, useMemo, useState } from 'react';
import { camelCase } from 'lodash';

import getLdClient from './getLdClient';

import FeatureFlagContext from './featureFlagsContext';

interface FeatureFlagsProviderProps {
  children: React.ReactNode,
  clientId: string,
  userId: string | number,
}

const FeatureFlagsProvider = ({ children, clientId, userId }: FeatureFlagsProviderProps) => {
  const [flags, setFlags] = useState(null);

  useEffect(() => {
    if (userId) {
      getLdClient(clientId, {
        key: userId.toString(),
      }).then((ldClient) => {
        const ldFlags = ldClient.allFlags();
        const ldFlagsCamelCase = Object.keys(ldFlags).reduce((acc, key) => ({
          ...acc,
          [camelCase(key)]: ldFlags[key],
        }), {});
        setFlags(ldFlagsCamelCase);
      });
    } else {
      // eslint-disable-next-line no-console
      console.info('LaunchDarkly service not specified, User-Id not found in cookie.');
    }
  }, []);

  const ctxValue = useMemo(() => ({
    flags: flags || {},
    initialized: flags !== null,
  }), [flags]);

  return (
    <FeatureFlagContext.Provider
      value={ctxValue}
    >
      { children }
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagsProvider;
