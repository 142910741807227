import { useContext } from 'react';

import FeatureFlagsContext from './featureFlagsContext';

function useFeatureFlag(flagId: string) {
  const { flags } = useContext(FeatureFlagsContext);

  return flags[flagId] || false;
}

export default useFeatureFlag;
