import { useContext } from 'react';

import FeatureFlagsContext from './featureFlagsContext';

function useFeatureFlagsInitialized() {
  const { initialized } = useContext(FeatureFlagsContext);

  return initialized;
}

export default useFeatureFlagsInitialized;
