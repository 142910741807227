import React, { RefCallback, RefObject } from 'react';
import cn from 'classnames';

import { Box } from '../../../common/components/box';
import { CommonComponent } from '../../../types';

// classes: styles/components/_viewLayout.scss

// ViewLayout ===================================
export interface ViewLayoutProps extends CommonComponent {
  children?: React.ReactNode;
}

export const ViewLayout = ({
  children,
  className,
}: ViewLayoutProps) => (
  <Box className={cn('jobox__view-layout', className)}>
    { children }
  </Box>
);

// ViewLayoutHeader =============================
export interface ViewLayoutHeaderProps extends CommonComponent {
  children?: React.ReactNode;
}

export const ViewLayoutHeader = ({
  children,
  className,
}: ViewLayoutHeaderProps) => (
  <Box className={cn('jobox__view-layout__header', className)}>
    { children }
  </Box>
);

// ViewLayoutContent ============================
export interface ViewLayoutContentProps extends CommonComponent {
  children?: React.ReactNode;
  innerRef?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement>;
}

export const ViewLayoutContent = ({
  children,
  className,
  innerRef,
}: ViewLayoutContentProps) => (
  <Box className={cn('jobox__view-layout__content', className)} ref={innerRef}>
    { children }
  </Box>
);
