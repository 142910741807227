import React from 'react';
import cn from 'classnames';

import { useTransitionShouldRender } from '../../../hooks';
import { Box } from '../../../common/components/box';

import { CommonComponent } from '../../../types';

export interface DialogProps extends CommonComponent {
  children: React.ReactNode;
  open: boolean;
  onToggle: () => void,
}

export const Dialog = ({ children, className, open }: DialogProps) => {
  const {
    visible,
    shouldRender,
    transitionedElementRef,
  } = useTransitionShouldRender({ open });

  return shouldRender ? (
    <Box
      ref={transitionedElementRef}
      className={cn(className, 'jobox__dialog', {
        'jobox__dialog--active': visible,
      })}
    >
      { children }
    </Box>
  ) : null;
};
