import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface HeaderProps extends CommonComponent {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  children: React.ReactNode | string,
}

export const Header = ({
  type,
  className,
  children,
}: HeaderProps) => {
  const Component = type || 'h1';

  return (
    <Component className={cn(className, 'jobox__header')}>
      { children }
    </Component>
  );
};
