import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

import { ToggleButtonProps } from './toggleButton';

interface ToggleButtonsCSSProperties extends React.CSSProperties {
  '--active-index': number;
  '--total-items': number;
}

export interface ToggleButtonsProps extends CommonComponent {
  activeId?: string | number;
  onToggle?: (activatedId: string | number) => void,
  children: React.ReactNode,
}

export const ToggleButtons = ({
  activeId,
  children,
  className,
  onToggle,
}: ToggleButtonsProps) => {
  const toggleButtons = React.Children.toArray(children)
    .filter(React.isValidElement) as React.ReactElement<ToggleButtonProps>[];

  const alteredChildren = toggleButtons.map((button) => (
    React.cloneElement(button, {
      onActivated: onToggle,
      isActive: activeId === button.props.id,
    })
  ));

  const ids = toggleButtons.map((button) => button.props.id).filter(Boolean);

  const activeIndex = ids.indexOf(activeId);

  return (
    <div
      className={cn(className, 'jobox__toggle-buttons')}
      style={{
        '--active-index': activeIndex,
        '--total-items': toggleButtons.length,
      } as ToggleButtonsCSSProperties}
    >
      { alteredChildren }
    </div>
  );
};
