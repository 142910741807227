import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';

import {
  number,
  numberRange,
  pipeValidators,
} from '../../../validators';
import { FormValidator } from '../../../types';

function roundToDigits(value: string | number | undefined, digits = 2) {
  if (typeof value === 'number') {
    return Number.parseFloat(value.toFixed(digits));
  }
  return 0;
}

export interface NumberFieldProps extends FieldProps {
  numberType?: 'integer' | 'float';
  min?: number;
  max?: number;
  digits?: number;
  validate?: FormValidator,
  children: (params: FieldProps) => React.ReactNode | React.ReactElement,
}

export const NumberField = ({ children, ...props }: NumberFieldProps) => {
  const {
    numberType,
    min,
    max,
    digits,
    validate,
    ...fieldProps
  } = {
    numberType: 'integer',
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    digits: 0,
    validate: () => undefined,
    ...props,
  };

  const numberValidators = pipeValidators(
    number(),
    numberRange(min, max),
    validate,
  );

  const parseValue = (value: string | number | undefined) => {
    if (typeof value === 'string' && value) {
      // Parse to Number
      const nVal = numberType === 'integer'
        ? Number.parseInt(value, 10) : Number.parseFloat(value);

      if (Number.isNaN(nVal)) {
        return 0;
      }

      // Round Floats to appropriate Precissions
      return numberType === 'float' ? roundToDigits(nVal, digits) : nVal;
    }

    return value;
  };

  return (
    <Field
      {...fieldProps}
      validate={numberValidators}
      type="number"
    >
      {
        ({ field, form, meta }: FieldProps<number, unknown>) => {
          const [localValue, setLocalValue] = useState(
            parseValue(field.value)?.toString() ?? '',
          );

          const inputProps = {
            ...field,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setLocalValue(e.target.value);

              field.onChange(e);
            },
            onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
              const valueParsed = parseValue(ev.target.value);
              form.setFieldValue(field.name, valueParsed, true);
              if (typeof valueParsed === 'number') {
                setLocalValue(
                  numberType === 'float'
                    ? valueParsed.toFixed(digits)
                    : valueParsed.toString(),
                );
              } else {
                setLocalValue(valueParsed);
              }

              field.onBlur(ev);
            },
            type: 'number',
            inputMode: numberType === 'float' ? 'decimal' : 'numeric',
            step: 1 / (10 ** digits),
            value: localValue,
            lang: 'en',
            min,
            max,
          };

          if (typeof children === 'function') {
            return children({ field: inputProps, form, meta });
          }
          if (React.isValidElement(children)) {
            return React.cloneElement(children, {
              ...(children as React.ReactElement).props,
              field: inputProps,
              form,
              meta,
            });
          }

          throw new Error('Invalid child, expected InputField, or render function');
        }
      }
    </Field>
  );
};
