import React from 'react';
import cn from 'classnames';

import { CommonComponent } from '../../../types';

export interface TextProps extends CommonComponent {
  children?: React.ReactNode | string;
  multiline?: boolean;
  type?: 'normal' | 'alt';
}

export const Text = ({
  children,
  multiline,
  className,
  type,
}: TextProps) => {
  const Component = multiline ? 'p' : 'span';

  return (
    <Component className={cn(className, 'jobox__text', `jobox__text--${type || 'normal'}`)}>
      { children }
    </Component>
  );
};
