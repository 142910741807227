import { createContext } from 'react';

const FeatureFlagContext = createContext<{
  flags: { [key: string]: boolean },
  initialized: boolean,
}>({
  flags: {},
  initialized: false,
});

FeatureFlagContext.displayName = 'FeatureFlagContext';

export default FeatureFlagContext;
