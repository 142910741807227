import React from 'react';
import cn from 'classnames';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Box } from '../box';
import { Icon } from '../icon';

import { CommonComponent } from '../../../types';

export interface ToggleSwitchProps extends CommonComponent {
  id: string;
  enabled?: boolean,
  onChange?: (value: boolean) => void,
  inputProps?: {
    name?: string,
  }
}

export const ToggleSwitch = ({
  id,
  enabled,
  onChange = () => {},
  inputProps = {},
  className,
}: ToggleSwitchProps) => (
  <Box className={cn(className, 'jobox__toggle-switch', `jobox__toggle-switch--${enabled ? 'enabled' : 'disabled'}`)}>
    <label htmlFor={id}>
      <input id={id} type="checkbox" onChange={() => onChange(!enabled)} checked={enabled} {...inputProps} />
      <Box className="jobox__toggle-switch__icon-wrap">
        <Icon icon={enabled ? faCheck : faTimes} />
      </Box>
    </label>
  </Box>
);
